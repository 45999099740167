import React, {useState} from 'react';
import Fade from '@mui/material/Fade'; //<Fade in={!loading} timeout={500}>
import Grow from '@mui/material/Grow';

// Import styles
import '../styles/Home.scss';

// Import components
import Loading from './Loading';

const Home = ({
    name,
    loading,
    setLoading
}) => {
    return (
        <>
            {loading && <Loading />}
            <Grow in={!loading} timeout={500} style={{ transformOrigin: 'bottom' }}>
                <div className={`home ${loading ? 'loading' : ''}`}>
                    {/* <span className='home-title title fancy'>October 27, 2024</span> */}
                    <div className='home-content-container'>
                        <div className='home-img-container'>
                            <img className='home-img ce-2' src="/ce_3_new.jpg" alt="image" />
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <img className='bubble-img' src="/ce_3_new.jpg" alt="image" />
                                We're getting married October 27, 2024! 👰‍♀️🥳✨
                            </div>
                            <div className='text-bubble'>
                                <img className='bubble-img' src="/ce_3_new.jpg" alt="image" />
                                ...in France! 🥖🍷 But don't worry, you don't need to speak French to come!
                            </div>
                            <div className='text-bubble'>
                                <img className='bubble-img' src="/ce_3_new.jpg" alt="image" />
                                Check out the rest of our site find out about about our story, the venue, and how to get here! 🎉
                            </div>
                        </div>
                    </div>
                </div>
            </Grow>
        </>
    );
}

export default Home;