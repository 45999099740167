import React, {useState, useEffect} from 'react';

// Import styles
import '../styles/Layout.scss';

// Import Components
import MenuButton from './MenuButton';
import Home from './Home';
import Story from './Story';
import Venue from './Venue';
//import Travel from './Travel';
import Registry from './Registry';
import RSVP from './RSVP';

const Layout = () => {
    const [menuActive, setMenuActive] = useState({default: true});
    const [loading, setLoading] = useState(true);

    const handleMenuClick = (menu) => {
        // If the menu hasn't changed, don't do anything
        if (!menuActive[menu]) {
            let newMenuActive = menuActive;
            for (let key in newMenuActive) {
                newMenuActive[key] = false;
            }
            
            setMenuActive({
                ...newMenuActive,
                [menu]: true,
            });
        }
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [menuActive]);

    return (
        <div className='layout'>
            <div className='menu'>
                <span className='title fancy'>
                    <img className='title-img' src="/people.png" alt="image" />
                    Elizabeth & Cole
                </span>

                <span className='menu-items'>
                    <MenuButton text="Home" onClick={() => handleMenuClick('default')} active={menuActive.default}/>
                    <MenuButton text="Our Story" onClick={() => handleMenuClick('story')} active={menuActive.story}/>
                    <MenuButton text="The Venue" onClick={() => handleMenuClick('venue')} active={menuActive.venue}/>
                    {/* <MenuButton text="Travel" onClick={() => handleMenuClick('travel')} active={menuActive.travel}/> */}
                    <MenuButton text="Registry" onClick={() => handleMenuClick('registry')} active={menuActive.registry}/>
                    <MenuButton text="RSVP" onClick={() => handleMenuClick('rsvp')} active={menuActive.rsvp}/>
                </span>
            </div>
            <div className='content-container'>
                <div className='content'>
                    {menuActive.default && <Home name={'Home'} loading={loading} setLoading={setLoading} />}
                    {menuActive.story && <Story name={'Story'} loading={loading} setLoading={setLoading} />}
                    {menuActive.venue && <Venue name={'Venue'}  loading={loading} setLoading={setLoading} />}
                    {/* {menuActive.travel && <Travel name={'Travel'} loading={loading} setLoading={setLoading} />} */}
                    {menuActive.registry && <Registry name={'Registry'} loading={loading} setLoading={setLoading} />}
                    {menuActive.rsvp && <RSVP name={'RSVP'} loading={loading} setLoading={setLoading} />}
                </div>
            </div>
        </div>
    );
}

export default Layout;