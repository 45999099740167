import React, {useState} from 'react';
import Fade from '@mui/material/Fade'; //<Fade in={!loading} timeout={500}>
import Grow from '@mui/material/Grow';

// Import styles
import '../styles/Venue.scss';

// Import Components
import CaptionImage from './CaptionImage';
import Loading from './Loading';

const Venue = ({
    name,
    loading,
    setLoading
}) => {
    return (
        <>
            {loading && <Loading />}
            <Grow in={!loading} timeout={500} style={{ transformOrigin: 'bottom' }}>
                <div className={`venue ${loading ? 'loading' : ''}`}>
                    <CaptionImage 
                        url='https://hoteloclock.com/wp-content/uploads/2023/02/PalaceMenthon-photo-Colastudio-20-1024x585.jpg' 
                        size={'50%'}
                        caption={<>
                            <h4>Le Palace de Menthon</h4>
                            <p>
                                Nestled on a magnificent natural setting, the palace is a true architectural jewel with the majestic facades characteristic of the Palaces of the 1900s.
                            </p>
                            <p>
                                We knew the moment we visited that Le Palace de Menthon was our place to say "I do".
                            </p>
                        </>} 
                    />

                    <CaptionImage 
                        url='https://www.civitatis.com/f/suiza/ginebra/tour-annecy-ginebra-589x392.jpg' 
                        size={'50%'}
                        caption={<>
                            <h4>Annecy, France</h4>
                            <p>
                                This is a picturesque town known as the "Venice of the Alps", where canals weave through historic streets and pastel-colored houses.
                            </p>
                            <p>
                                With its crystal-clear lake and stunning mountain backdrop, it's a place that truly feels like a fairy-tale come to life.
                            </p>
                        </>} 
                    />

                    <CaptionImage 
                        url='/annecy_travel.png' 
                        size={'50%'}
                        caption={<>
                            <h4>How to Get Here</h4>
                            <p>
                                You won't regret making the trip out to Annecy! Just fly into Geneva International Airport, and from there, it's a scenic 45-minute drive or a delightful train ride.
                            </p>
                            <p>
                                You'll be whisked away through stunning landscapes straight to the heart of this charming town.
                            </p>
                        </>} 
                    />
                </div>
            </Grow>
        </>
    );
}

export default Venue;