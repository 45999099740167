import React, {useState} from 'react';
import SouthIcon from '@mui/icons-material/South';
import Fade from '@mui/material/Fade'; //<Fade in={!loading} timeout={500}>
import Grow from '@mui/material/Grow';

// Import styles
import '../styles/Story.scss';

// Import components
import Loading from './Loading';

const Story = ({
    name,
    loading,
    setLoading
}) => {
    return (
        <>
            {loading && <Loading />}
            <Grow in={!loading} timeout={500} style={{ transformOrigin: 'bottom' }}>
                <div className={`story ${loading ? 'loading' : ''}`}>

                    {/* Event 1 */}
                    <div className='event-container'>
                        <div className='event-img-container'>
                            <img className='img' src="/story_2.png" alt="image" />
                            <div className='date'>2016 - Lubbock, Texas</div>
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                We met at a mutual friends bday party and ended up hitting it off and hanging out all night 🥳
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                But I was about to graduate in two weeks! 😲 What a pickle to be in 😿
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                Sooo why not move in together? 🤤 Good idea right!?
                            </div>
                        </div>
                    </div>
                    <SouthIcon />

                    {/* Event 2 */}
                    <div className='event-container'>
                        <div className='event-img-container'>
                            <img className='img' src="/story_3_new.jpg" alt="image" />
                            <div className='date'>2016 - Palo Duro Canyon, Texas</div>
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                Yay this is when we got Nookie! 😍
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                Oh fiddlesticks!🤬 Don't get her started about that dog again...
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                Don't pretend like you don't like her as much as I do! You're the one who made her take this picture! 📸
                            </div>
                        </div>
                    </div>
                    <SouthIcon />

                    {/* Event 3 */}
                    <div className='event-container'>
                        <div className='event-img-container'>
                            <img className='img' src="/story_4.png" alt="image" />
                            <div className='date'>2019 - Puerto Vallarta, Mexico</div>
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                This is when she took me to Mexico
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                Damn right! Had to season that white ass 🍑
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                Dios mío, muy caliente 🥵
                            </div>
                        </div>
                    </div>
                    <SouthIcon />

                    {/* Event 4 */}
                    <div className='event-container'>
                        <div className='event-img-container'>
                            <img className='img' src="/story_7.png" alt="image" />
                            <div className='date'>2022 - Montpellier, France</div>
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                This is around the time we really got into traveling
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                I wish you kissed me like that Cole! 🐶😘
                            </div>
                        </div>
                    </div>
                    <SouthIcon />

                    {/* Event 5 */}
                    <div className='event-container'>
                        <div className='event-img-container'>
                            <img className='img' src="/story_6.jpg" alt="image" />
                            <div className='date'>2024 - Annecy, France</div>
                        </div>
                        <div className='bubble-container'>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                ...And we ended up loving France so we decided to stay!
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                They're not as smelly as everyone says!
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img cole' alt="image"></div>
                                But just as gay... 🏳️‍🌈
                            </div>
                            <div className='text-bubble'>
                                <div className='bubble-img elli' alt="image"></div>
                                😒...anywayyys and this is the castle up the street from where we'll be having our wedding! 💒 
                            </div> 
                        </div>
                    </div>
                </div>
            </Grow>
        </>
    );
}

export default Story;