import React, {useState, useEffect} from 'react';

// Import styles
import '../styles/CaptionImage.scss';

const CaptionImage = ({
    url,
    size,
    caption
}) => {
    const [dimensions, setDimensions] = useState({width: 0, height: 0, aspectRatio: 0 });
    const [zoomedIn, setZoomedIn] = useState(false);
    useEffect(() => {
        const image = new Image();
        image.src = url;
        image.onload = () => {
            setDimensions({
                width: image.width,
                height: image.height,
                aspectRatio: image.width / image.height
            });
        }
    }, [url]);

    return (
        <div className={`caption-image ${zoomedIn ? 'zoomed' : ''}`} style={{maxWidth: size}}>
            <img 
                className={`img`}
                src={url} alt="image" 
                style={{aspectRatio: dimensions.aspectRatio, width: size}}
                onClick={(e) => {
                    setZoomedIn(!zoomedIn);
                }}
            />
            <span className='caption'>{caption}</span>
        </div>
    );
}

export default CaptionImage;