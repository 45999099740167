import React, { useState } from 'react';

// Import styles
import './App.scss';

// Import components
import Layout from './components/Layout';

const App = () => {
  return (
    <>
      <div className='root'>
        <div className='bg-img-container-1'>
          <img className='castle-img' src="/castle.png" alt="image" />
        </div>
        <div className='bg-img-container-2'>
          <img className='fields-img' src="/fields.png" alt="image" />
        </div>
        <Layout />
      </div>
    </>
  );
};

export default App;
