import React, {useState} from 'react';
import Fade from '@mui/material/Fade'; //<Fade in={!loading} timeout={500}>
import Grow from '@mui/material/Grow';

// Import styles
import '../styles/Registry.scss';

// Import components
import Loading from './Loading';

const Registry = ({
    name,
    loading,
    setLoading
}) => {
    const [showOptions, setShowOptions] = useState(false);
    return (
        <>
            {loading && <Loading />}
            <Grow in={!loading} timeout={500} style={{ transformOrigin: 'bottom' }}>
                <div className={`registry ${loading ? 'loading' : ''}`}>
                    <h3 className='title'><b>We don't have a registry!</b> So don't worry about getting us anything!</h3>
                    <br />
                    <span className='secret-text'>
                        But if you&nbsp;<i>reallllly</i>&nbsp;want to give us something, you can help us out by donating a few bucks to our travel fund:&nbsp;
                    </span>
                    <button
                        className='options-button'
                        style={{display: showOptions ? 'none' : 'block'}}
                        onClick={() => setShowOptions(!showOptions)}
                    >
                        See Options
                    </button>
                    
                    <div className='donate-container' style={{display: showOptions ? 'flex' : 'none'}}>
                        <div className='donate-img-container'>
                            <span className='name'>Paypal: <span className='link' onClick={() => window.open('https://paypal.me/ColeKuivenhoven?country.x=US&locale.x=en_US')}>Open Link</span></span>
                            <img className='donate-img' id="paypal" src="/paypal_qr_code.png" alt="image" />
                            
                        </div>
                        <div className='donate-img-container'>
                            <span className='name'>CashApp: <span className='link' onClick={() => window.open('https://cash.app/$coldkui/')}>Open Link</span></span>
                            <img className='donate-img' id="cashapp" src="/cashapp_qr_code.jpg" alt="image" />
                            
                        </div>
                    </div>
                </div>
            </Grow>
        </>
    );
}

export default Registry;