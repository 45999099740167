// Import styles
import '../styles/MenuButton.scss';

const MenuButton = ({
    text,
    onClick,
    active
}) => {
    return (
        <div className={`menu-button ${active ? 'active' : ''}`} onClick={onClick}> 
            <span className='menu-text'>{text}</span>
        </div>
    );
}

export default MenuButton;