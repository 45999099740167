import React, {useState} from 'react';
import Fade from '@mui/material/Fade'; //<Fade in={!loading} timeout={500}>
import Grow from '@mui/material/Grow';

// Import styles
import '../styles/RSVP.scss';

// Import components
import Loading from './Loading';

const RSVP = ({
    name,
    loading,
    setLoading
}) => {
    return (
        <>
            {loading && <Loading />}
            <Grow in={!loading} timeout={500} style={{ transformOrigin: 'bottom' }}>
                <div className={`rsvp ${loading ? 'loading' : ''}`}>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeYk_xdfu17VGFRo8aXngrmGbz9j33FYT4yfEheKgqkfPEROA/viewform?embedded=true" width="640" height="622" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            </Grow>
        </>
    );
}

export default RSVP;